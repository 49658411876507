export default {
  namespaced: true,
  state: {
    user: null,
    slug: "",
    communityType: "",
    communityId: null,
  },
  mutations: {
    setUserInformation(state, data) {
      state.userData = data
    },
    setSlug(state, slug) {
      state.slug = slug
    },
    setCommunityId(state, id) {
      state.communityId = id
    },
    setCommunityType(state, type) {
      state.communityType = type
    }
  },
  getters: {
    loggedInUserInformation: state => {
      const { userData } = state
      return userData
    },
  },
  actions: {},
}
